import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import QRCode from '@/components/layout/QRCode/QRCode.vue'

export default {
  components: {
    [BreadCrumb.name]: BreadCrumb,
		QRCode
  },

  data: () => ({
    isEmployee: true,
    isSingle: false
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'QRCode Funcionários e Visitantes',
            active: true
          }
        ]
      }
		}
	},

  created() {
  },

  methods: {
    onSetTab(tabName) {
      if  (tabName == 'isSingle') {
        this.isSingle = true
        this.isEmployee = false
        this.$refs.QRCodeEmployee.clearInputs()
        return
      }
      
      if  (tabName == 'isEmployee') {
        this.isSingle = false
        this.isEmployee = true
        this.$refs.QRCodeSingle.clearInputs()
        return
      }
    }
  }
}
